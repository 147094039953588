.profile-modal{
  padding: 20px 0 0;
  @include tablet{
    padding: 40px 0 0;
  }
  .profile-head{
    text-align: center;
    padding-bottom: 30px;
    .user-title{
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: bold;
      text-transform: capitalize;
    }
    .user-phone{
      margin-top: 5px;
    }
    .profile-btn{
      min-height: 40px;
      margin: 20px auto 0;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
    }
    .email-row{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      .verify-email-col{
        position: relative;
        margin-bottom: 1px;
        .error-icon{
          height: 14px;
          margin-left: 5px;
        }
        .verify-email-container{
          position: absolute;
          top: 100%;
          left: -10px;
          padding-top: 7px;
          display: none;
        }
        .verify-email-popup{
          padding: 8px 12px 8px 12px;
          box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
          background-color: $primary-text-color;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          white-space: nowrap;
          cursor: pointer;
          width: 130px;
          position: relative;
          &:before{
            content: '';
            width: 6px;
            height: 6px;
            background-color: $primary-text-color;
            position: absolute;
            left: 20px;
            top: -3px;
            transform: rotate(45deg);
          }
          img{
            margin-left: 4px;
            margin-bottom: 3px;
          }
        }
        &:hover{
          .verify-email-container{
            display: block;
          }
        }
      }
    }
  }
  .account-container{
    padding: 30px 0;
    text-align: center;
    box-shadow: 0 -1px 0 0 rgb(88 88 88 / 12%);
    .account-label{
      font-family: $secondary-font;
      font-size: 24px;
    }
    .account-value{
      font-family: $secondary-font;
      font-size: 40px;
      font-weight: bold;
      color: $primary-green;
      margin-top: 9px;
    }
    .account-subtitle{
      font-size: 16px;
      margin-top: 3px;
      span{
        color: $primary-green;
        font-weight: bold;
      }
    }
    .card-btn{
      text-align: center;
      margin-top: 20px;
      span{
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .bank-info-container{
    padding: 30px 0;
    text-align: center;
    box-shadow: 0 -1px 0 0 rgb(88 88 88 / 12%);
    .bank-name, .card-number{
      font-family: $secondary-font;
      font-size: 24px;
      color: $primary-green;
      margin-bottom: 8px;
    }
    .profile-link-btn{
      margin-top: 7px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      &.selected{
        color: $primary-green;
      }
    }
  }
  .profile-links-container{
    .profile-link-row{
      padding: 19px 10px 21px;
      box-shadow: 0 -1px 0 0 rgba(88, 88, 88, 0.12);
      background-color: rgba(249, 249, 249, 0);
      text-align: center;
      .profile-link-btn{
        font-family: $secondary-font;
        font-size: 24px;
        cursor: pointer;
        display: inline-block;
        &.selected {
          color: $primary-green;
        }
      }
      &.logout-btn{
        .profile-link-btn{
          font-size: 18px;
          font-weight: 400;
          color: $primary-red;
          position: relative;
          background-color: transparent;
          border: 0 !important;
          box-shadow: none;
          min-height: auto;
          transform: none;
          width: 144px;
          &::after{
            display: none;
          }
        }
        .dropdown-menu{
          padding: 0;
          box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
          background-color: $primary-text-color !important;
          border-radius: 0;
          border: 0;
          min-width: auto;
          .dropdown-item{
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            padding: 12px;
            background-color: $primary-text-color !important;
          }
        }
      }
    }
  }
}

.modal-card{
  .modal-subtitle{
    font-size: 16px;
    margin-top: 14px;
  }
  .card-options-col{
    margin-top: 20px;
    .card-option-row{
      padding: 16px 20px 17px;
      border: solid 1px #303734;
      background-color: #fff;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-text{
        font-size: 15px;
        font-weight: 600;
      }
      &.selected{
        box-shadow: 0 2px 0 0 #222;
      }
    }
  }
  .card-modal-btns{
    margin-top: 32px;
  }
}

.transaction-list{
  border-radius: 8px;
  border: solid 1px #f7f7f7;
  background-color: #fff;
  margin-top: -18px;
  .transaction-list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f7f7f7;
    padding: 20px;
    &:last-child{
      border-bottom: 0;
    }
    .transaction-title{
      font-weight: bold;
    }
    .transaction-date{
      font-size: 12px;
      color: #8d8d8d;
    }
    .transaction-amount{
      font-family: $secondary-font;
      font-size: 24px;
      font-weight: 500;
      margin-left: 25px;
      white-space: nowrap;
      &.credited{
        color: $primary-green;
      }
    }
  }
}
.no-result-layout{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  .no-result-title{
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: bold;
  }
}
