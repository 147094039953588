.project-container{
  min-height: 100vh;
  position: relative;
  padding: 30px 0 40px;
  @include tablet{
    padding: 40px 0;
  }
  .bg-layout{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 362px;
    height: 100%;
    background-color: #dceddb;
    z-index: -1;
    @include tablet{
      max-height: 510px;
    }
  }
  .create-project-layout{
    margin-top: 40px;
    text-align: center;
    @include tablet{
      margin-top: 5px;
    }
    .custom-amount-popup-responsive{
      width: 322px;
      height: 108px;
      padding: 15px 16px 15px 21px;
      border-radius: 21px;
      box-shadow: 0 4px 10px 0 rgba(53, 58, 58, 0.04);
      background-color: #2b9967;
      display: flex;
      margin: 0 auto 30px;
      position: relative;
      &::after{
        content: '';
        width: 46px;
        height: 41px;
        position: absolute;
        right: 80px;
        bottom: -27px;
        background-image: url('../Images/popup-arrow-mobile.svg');
      }
      .amount-popup-title{
        font-family: $secondary-font;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        text-align: left;
      }
      .amount-popup-subtitle{
        margin-top: 4px;
        color: #fff;
        text-align: left;
      }
      .bg-icon{
        min-width: 62px;
        width: 62px;
        height: 62px;
      }
      @media screen and (min-width: 1301px) {
        display: none;
      }
    }
    .create-project-label{
      margin-top: 22px;
      font-family: $secondary-font;
      font-size: 27px;
      font-weight: bold;
      @include tablet{
        margin-top: 18px;
        font-size: 45px;
      }
    }
    &.modal-in-page{
      margin-top: 60px;
      .modal-subtitle{
        max-width: 748px;
      }
    }
    .project-label{
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .project-input-title{
      font-family: $secondary-font;
      font-size: 28px;
      font-weight: bold;
      margin-top: 22px;
      max-width: 430px;
      margin-left: auto;
      margin-right: auto;
      @include tablet{
        max-width: 680px;
        font-size: 45px;
      }
      &.estimate-input-title{
        max-width: none;
      }
    }
    .project-title-input{
      margin: 28px auto 0;
      max-width: 823px;
      width: 100%;
      border-bottom: solid 1px #303734;
      padding-bottom: 19px;
      &.invalid{
        border-color: $primary-red;
      }
      input{
        background-color: transparent;
        border: 0;
        outline: none;
        box-shadow: none;
        font-size: 30px;
        font-family: $secondary-font;
        font-weight: bold;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include tablet{
          font-size: 55px;
        }
        &::-webkit-input-placeholder { /* Edge */
          font-family: $secondary-font;
          font-size: 30px;
          font-weight: bold;
          color: #a9bca8;
          text-align: center;
          opacity: 1;
          @include tablet{
            font-size: 55px;
          }
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-family: $secondary-font;
          font-size: 30px;
          font-weight: bold;
          color: #a9bca8;
          text-align: center;
          opacity: 1;
          @include tablet{
            font-size: 55px;
          }
        }
        
        &::placeholder {
          font-family: $secondary-font;
          font-size: 30px;
          font-weight: bold;
          color: #a9bca8;
          text-align: center;
          opacity: 1;
          @include tablet{
            font-size: 55px;
          }
        }
      }
    }
    .project-category-layout{
      margin-top: 28px;
      @include tablet{
        margin-top: 52px;
      }
      &.estimate-layout{
        margin-top: 12px;
      }
      .category-label{
        font-family: $secondary-font;
        font-size: 18px;
        @include tablet{
          font-size: 21px;
        }
        &.invalid-text{
          color: $primary-red;
        }
      }
      .category-tab-row{
        margin-top: 20px;
        .nav-tabs{
          justify-content: center;
          border: 0;
          flex-wrap: nowrap;
          overflow-x: auto;
          padding-bottom: 5px;
          max-width: 560px;
          margin: 0 auto;
          @media screen and (max-width: 617px) {
            justify-content: unset;
            margin-right: -30px;
            padding-right: 30px;
          }
          .nav-item{
            display: contents;
            .nav-link{
              font-family: $secondary-font;
              font-size: 16px;
              font-weight: 600;
              padding: 13px;
              border: solid 1px $primary-text-color;
              color: $primary-text-color;
              border-radius: 0;
              border-right: 0;
              min-width: 186px;
              &.active{
                box-shadow: inset 0 -3px 0 0 $primary-text-color;
              }
            }
            &:last-child{
              .nav-link{
                border-right: solid 1px $primary-text-color;
              }
            }
          }
        }
      }
      .project-category-wrap{
        margin: 0 auto;
        max-width: 315px;
        @include tablet{
          max-width: none;
          margin: 0;
        }
      }
      .project-category-row{
        display: flex;
        flex-wrap: wrap;
        max-width: 330px;
        margin: 30px -7.5px 0;
        @include tablet{
          max-width: none;
          margin: 40px -10px 0;
          justify-content: center;
        }
        .category-card{
          max-width: 150px;
          width: 100%;
          height: 180px;
          margin: 0 7.5px 30px;
          padding: 14px 13px 22px;
          background-color: #fff;
          border: 1.5px solid #ececec;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include tablet{
            max-width: 255px;
            height: 284px;
            margin: 0 10px 30px;
            padding: 14px 13px 42px;
          }
          &.selected{
            box-shadow: 0 4px 0 0 #000;
            border: solid 1.5px #000;
          }
          .category-icon{
            margin: 10px auto;
            max-width: 60px;
            @include tablet{
              margin: 10px 0;
              max-width: unset;
            }
          }
          .category-title{
            font-size: 16px;
            font-weight: bold;
            max-width: 146px;
            margin: 0 auto;
            @include tablet{
              font-size: 20px;
            }
          }
        }
      }

      .project-estimate-row{
        margin: 20px auto 0;
        max-width: 713px;
        @include tablet{
          margin: 27px auto 0;
        }
        .estimate-card{
          padding: 20px 50px 20px 15px;
          border: solid 1px #000;
          background-color: #fff;
          width: 100%;
          margin: 0 0 15px;
          text-align: left;
          position: relative;
          @include tablet{
            padding: 22px 50px 22px 30px;
          }
          &.selected{
            box-shadow: 0 4px 0 0 #000;
          }
          .estimate-title{
            font-family: $secondary-font;
            font-size: 20px;
            font-weight: bold;
          }
          .estimate-description{
            margin-top: 4px;
          }
          .estimate-value{
            margin-top: 15px;
            span{
              font-size: 18px;
              font-weight: bold;
            }
            .high-end-cost{
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              margin: 0 -5px;
              @include tablet{
                margin: 0 -10px;
              }
              label{
                position: relative;
                .form-check-input{
                  position: absolute;
                  left: 20px;
                  top: 4px;
                  visibility: hidden;
                  &:checked{
                    + .cost-value{
                      background-color: $primary-color;
                    }
                  }
                }
              }
              .cost-value{
                font-size: 14px;
                font-weight: bold;
                border-radius: 20px;
                border: solid 1px #303734;
                margin: 10px 5px 0;
                height: 36px;
                padding: 8px 18px;
                background-color: #fff;
                cursor: pointer;
                @include tablet{
                  font-size: 18px;
                  margin: 10px 10px 0;
                  height: 40px;
                  padding: 8px 25px;
                }
                &.selected{
                  background-color: $primary-color;
                }
              }
            }
          }
          .estimate-radio{
            position: absolute;
            right: 15px;
            top: 17px;
          }
        }
        .estimate-note{
          font-size: 12px;
          font-style: italic;
          max-width: 650px;
          text-align: left;
          span{
            color: $primary-red;
          }
        }
      }
      .category-btn{
        margin-top: 20px;
        @include tablet{
          margin-top: 52px;
        }
        .btn{
          margin: 0 auto;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
        .btn-danger{
          margin-top: 20px;
        }
        &.estimate-btn{
          margin-top: 40px;
        }
      }
    }
  }
}

.close-icon{
  display: flex;
  justify-content: flex-end;
  img{
    cursor: pointer;
    width: 16px;
    @include tablet{
      width: auto;
    }
  }
}

.modal-project{
  text-align: center;
  .modal-title{
    margin: 30px auto 0;
    max-width: 430px;
  }
  .modal-subtitle{
    font-size: 14px;
    margin: 11px auto 15px;
    max-width: 490px;
    @include tablet{
      font-size: 16px;
      margin: 15px auto;
    }
  }
  .project-input-title{
    max-width: 985px;
    margin: 0 auto;
  }
  .project-plan-title{
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: bold;
    max-width: 430px;
    margin: 25px auto 0;
    @include tablet{
      font-size: 35px;
      margin: 30px auto 0;
    }
  }
  .plan-note{
    font-size: 15px;
    font-weight: bold;
    max-width: 690px;
    margin: 9px auto 40px;
    span{
      color: $primary-red;
    }
  }
  .modal-btns{
    .btn{
      min-height: 46px;
      + .btn{
        margin-top: 20px;
      }
      &.btn-primary{
        min-width: 210px;
      }
    }
  }
  .labour-cost{
    margin-top: 19px;
    span{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .mxw-500{
    max-width: 500px;
    margin: 0 auto;
  }
}
.custom-amount-input{
  max-width: 5150px;
  margin: 28px auto 40px;
  display: inline-block;
  position: relative;
  @include tablet{
    margin: 40px auto 80px;
  }
  .custom-amount-popup{
    position: absolute;
    left: calc(100% + 40px);
    top: -50px;
    width: 350px;
    height: 108px;
    padding: 15px 16px 15px 29px;
    border-radius: 21px;
    box-shadow: 0 4px 10px 0 rgba(53, 58, 58, 0.04);
    background-color: #2b9967;
    display: flex;
    &::before{
      content: '';
      width: 44px;
      height: 47px;
      position: absolute;
      left: -33px;
      bottom: 7px;
      background-image: url('../Images/popup-arrow.svg');
    }
    .amount-popup-title{
      font-family: $secondary-font;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      text-align: left;
    }
    .amount-popup-subtitle{
      margin-top: 4px;
      color: #fff;
      text-align: left;
    }
    .bg-icon{
      min-width: 62px;
      width: 62px;
      height: 62px;
    }
    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
  input{
    font-family: $secondary-font;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    border: 1px solid $primary-text-color;
    padding: 14px 20px;
    box-shadow: 0 4px 0 0 #000 !important;
    text-align: center;
    min-height: 58px;
    @include tablet{
      font-size: 40px;
      padding: 14px 15px 15px;
    }
    &::-webkit-input-placeholder { /* Edge */
      font-family: $secondary-font;
      font-size: 26px;
      font-weight: bold;
      color: #d8d8d8;
      text-align: center;
      opacity: 1;
      @include tablet{
        font-size: 40px;
      }
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-family: $secondary-font;
      font-size: 26px;
      font-weight: bold;
      color: #d8d8d8;
      text-align: center;
      opacity: 1;
      @include tablet{
        font-size: 40px;
      }
    }
    
    &::placeholder {
      font-family: $secondary-font;
      font-size: 26px;
      font-weight: bold;
      color: #d8d8d8;
      text-align: center;
      opacity: 1;
      @include tablet{
        font-size: 40px;
      }
    }
  }
}

.static-content-layout{
  padding-top: 5px;
  padding-bottom: 50px;
  max-width: 1096px;
  .tnc-title{
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    @include tablet{
      font-size: 45px;
    }
  }
}
.tnc-container{
  margin-top: 30px;
  box-shadow: 0 4px 10px 0 rgba(53, 58, 58, 0.04);
  background-color: #fff;
  padding: 30px;
  @include tablet{
    padding: 80px 78px;
    margin-top: 50px;
  }
  .static-content{
    .static-title{
      font-size: 20px;
      font-weight: bold;
      line-height: 1.44;
      margin-bottom: 20px;
      @include tablet{
        font-size: 26px;
      }
    }
    .static-description{
      display: inline-block;
      margin-bottom: 28px;
      p{
        font-size: 15px;
        margin-bottom: 20px;
        line-height: 1.44;
        @include tablet{
          font-size: 16px;
        }
      }
    }
  }
}
.tnc-fixed-row{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: -20px -4px 24px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  padding: 22px 0;
  .tnc-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tnc-checkbox{
      .form-check{
        display: flex;
        align-items: center;
        .form-check-input{
          width: 30px;
          height: 30px;
        }
        .form-check-label{
          font-size: 18px;
          margin-left: 17px;
        }
      }
    }
  }
}

.kyc-docs-container{
  height: 228px;
  border: dashed 1px #303734;
  background-color: #fff;
  label{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .upload-label{
    font-size: 13px;
    font-weight: bold;
    margin-top: 11px;
  }
  .upload-text{
    font-size: 13px;
    margin-top: 3px;
  }
  .uploaded-doc{
    width: 100%;
    height: 100%;
    position: relative;
    .doc-img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .cross-img{
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      z-index: 999;
    }
    &::after{
      content: '';
      background-color: #00000040;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.cost-estimate-layout{
  text-align: center;
  margin-top: 18px;
  .estimate-label{
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .estimate-value{
    font-size: 35px;
    font-weight: bold;
    margin-top: 10px;
    color: $primary-color;
    @include tablet{
      font-size: 45px;
    }
  }
  .estimate-breakup{
    font-size: 12px;
  }
  .deposit-label{
    font-family: $secondary-font;
    font-size: 19px;
    font-weight: bold;
    margin-top: 36px;
  }
  .tenure-popup{
    padding: 13px 30px 11px;
    border-radius: 8px;
    background-color: #303734;
    margin-top: 37px;
    display: inline-block;
    min-width: 182px;
    .month-value{
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
    .interest-value{
      font-size: 12px;
      color: #fff;
      margin-top: 1px;
    }
  }
  .deposit-range-row{
    margin: 25px 0 40px;
    min-height: 90px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    // Custom Scrollbar transparent scroll
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    @media screen and (max-width: 1020px) {
      margin-right: -30px;
      overflow: auto;
    }
    @include tablet{
      margin: 22px 0 60px;
    }
    >span{
      min-height: 20px;
      @media screen and (max-width: 1020px) {
        min-width: 800px;
        margin-left: 28px;
      }
      @include tablet{
        margin-left: 34px;
      }
    }
    span{
      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }
  }
}
.deposit-day-card{
  background-color: #dceddb;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include tablet{
    align-items: center;
    padding: 36px 40px;
    flex-direction: row;
  }
  .deposit-date-text{
    max-width: 320px;
  }
  .deposit-date-select{
    max-width: 352px;
    width: 100%;
    margin-top: 20px;
    .form-select{
      background-image: url('../Images/datepicker-icon.svg');
      background-size: auto;
      padding-top: 10px;
    }
    .tenure-select__menu{
      margin-top: -4px;
    }
  }
  .deposit-day-title{
    font-family: $secondary-font;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .deposit-day-note{
    font-size: 12px;
    font-style: italic;
    margin-top: 5px;
    color: $primary-red;
  }
}

.disclosure-layout{
  max-width: 1096px;
  .disclosure-container{
    .disclosure-card{
      padding: 50px 20px;
      box-shadow: 0 4px 10px 0 rgba(53, 58, 58, 0.04);
      background-color: #fff;
      margin: 50px 0 100px;
      @include tablet{
        padding: 80px;
      }
      .disclosure-row{
        justify-content: flex-start;
        margin-bottom: 40px;
        &:last-child{
          margin-bottom: 0;
        }
        .form-check{
          display: flex;
          align-items: center;
          padding-left: 0;
          .form-check-input{
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-top: -12px !important;
            @include tablet{
              width: 30px;
              min-width: 30px;
              height: 30px;
              margin-top: 0 !important;
            }
          }
          .form-check-label{
            font-size: 16px;
            margin-left: 17px;
            @include tablet{
              font-size: 18px;
            }
            .label-link{
              text-decoration: underline;
              color: inherit;
            }
          }
        }
      }
    }
  }
}

.project-btns{
  max-width: 370px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
  .btn-outline{
    background-color: transparent;
  }
}

// Drop down style

.react-select-dropdown-custom{
  .tenure-select__control{
    border-radius: 0;
    border: solid 1px $primary-text-color !important;
    box-shadow: none;
    width: 100%;
    display: inline-flex;
    margin-bottom: 7px;
    .tenure-select__value-container{
      padding: 0 5px 0 15px;
      .tenure-select__placeholder, .tenure-select__single-value {
        margin-left: 0;
        font-size: 15px;
        font-weight: normal;
        color: $primary-text-color;
      }
    }
    .tenure-select__indicators{
      .tenure-select__indicator-separator{
        display: none;
      }
      .tenure-select__indicator{
        background-image: url('../Images/datepicker-icon.svg');
        background-size: auto;
        background-repeat: no-repeat;
        margin-right: 15px;
        padding: 8px;
        svg{
          display: none;
        }
      }
    }
  }
  .tenure-select__menu{
    border-radius: 0;
    border: 1px solid $primary-text-color;
    box-shadow: none;
    margin-top: -8px;
    z-index: 99;
    .tenure-select__menu-list{
      padding: 9.5px 0;
      margin-right: 8px;

      // Custom Scrollbar
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #d5d5d5;
        border-radius: 4px;
      }
      .tenure-select__option{
        padding: 7.5px 15px;
        font-size: 15px;
        font-weight: normal;
        &.tenure-select__option--is-selected{
          background-color: transparent;
          color: $primary-green;
        }
        &.tenure-select__option--is-focused{
          background-color: rgba(220, 237, 219, 0.3);
        }
      }
    }
  }
  &.invalid{
    .tenure-select__control{
      border-color: $primary-red !important;
    }
  }
}

.state-dropdown{
  .tenure-select__control{
    min-height: 48px;
  }
  .tenure-select__indicator{
    background-image: url('../Images/dropdown-black.svg') !important;
    background-repeat: no-repeat;
    padding: 4px 7px !important;
  }
}
