.property-detail-popup{
  position: absolute;
  top: 0;
  left: 0;
  width: 335px;
  height: auto;
  padding: 45px 20px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  display: none;
  max-height: calc(100vh - 160px);
  overflow: auto;
  z-index: 9;
  @include tablet{
    width: 460px;
    padding: 44px 45px 50px;
    max-height: calc(100vh - 140px);
  }
  @include mobile{
    width: 100vw;
    position: fixed;
    left: 0;
    bottom: 0;
    top: unset;
  }
  // Custom Scrollbar
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 2px;
  }
  .prop-title{
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 13px;
    @include tablet{
      font-size: 35px;
      margin-bottom: 15px;
    }
  }
  .prop-detal-col{
    display: flex;
    font-size: 15px;
    margin-bottom: 4px;
    .prop-label{
      font-weight: bold;
      white-space: nowrap;
    }
    .prop-value{
      font-weight: normal;
      margin-left: 5px;
    }
  }
  .prop-popup-icon{
    position: absolute;
    right: 21px;
    top: 25px;
    width: 56px;
    @include tablet{
      width: 69px;
    }
    img{
      width: 100%;
    }
  }
}

.dashboard-container{
  min-height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
  @include tablet{
    min-height: calc(100vh - 180px);
    padding-bottom: 50px;
  }
  .dashboard-title{
    font-family: $secondary-font;
    font-size: 30px;
    font-weight: bold;
    @include tablet{
      font-size: 45px;
    }
  }
  .dahboard-empty-layout{
    text-align: center;
    padding: 25px 0 60px;
    .dashboard-title{
      max-width: 753px;
      margin: 0 auto 36px;
      font-size: 35px;
    }
    .btn{
      margin: 0 auto;
      max-width: 350px;
      width: 100%;
    }
    .empty-img{
      font-family: $special-font;
      font-size: 26px;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      sup{
        top: -30px;
        left: 10px;
        img{
          min-width: 31px;
        }
      }
      @include tablet{
        margin-top: 40px;
      }
    }
  }
  .dashboard-no-result{
    text-align: center;
    padding: 140px 0 60px;
    @include tablet{
      padding: 100px 0 60px;
    }
    .dashboard-title{
      @include tablet{
        font-size: 35px;
      }
    }
    .dashboard-subtitle{
      font-size: 12px;
      margin-top: 7px;
    }
  }
  .dashboard-footer{
    text-align: center;
    margin-top: 35px;
    @include tablet{
      margin-top: 65px;
    }
    .footer-logo{
      width: 52px;
    }
    .footer-text{
      font-weight: 600;
      margin-top: 14px;
    }
    .footer-links{
      font-weight: bold;
      margin-top: 2px;
      a{
        color: #303734b3;
      }
    }
  }
}

.dashboard-layout{
  padding-top: 30px;
  min-height: calc(100vh - 372px);
  @include tablet{
    padding-top: 35px;
  }
  .dashboard-header{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include tablet{
      align-items: center;
      flex-direction: row;
    }
    .header-left{
      margin-bottom: 30px;
      @include tablet{
        margin-bottom: 0;
      }
      img{
        margin-right: 12.5px;
        margin-top: 2px;
      }
      .dashboard-header-title{
        font-size: 15px;
        font-weight: bold;
      }
      .dashboard-header-subtitle{
        font-size: 12px;
        margin-top: 4px;
        max-width: 446px;
      }
    }
    .header-right{
      .project-filter-col{
        position: relative;
        .filter-btn{
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          .filter-text{
            font-size: 12px;
            font-weight: 800;
            margin-left: 8.7px;
            text-transform: uppercase;
          }
        }
        .filter-popup{
          width: 254px;
          position: absolute;
          top: -2px;
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
          background-color: #fff;
          z-index: 99;
          left: 0;
          @include tablet{
            left: auto;
            right: -10px;
          }
          .filter-body{
            padding: 10px 23px 12px;
            .filter-label{
              font-family: $secondary-font;
              font-size: 16px;
              font-weight: bold;
              margin-top: 17px;
              margin-bottom: 14px;
            }
            .filter-checkbox{
              justify-content: flex-start;
              margin-bottom: 10px;
              min-height: auto;
              .form-check{
                padding-left: 0;
                margin-bottom: 0;
                .form-check-input{
                  width: 22px;
                  height: 22px;
                }
                .form-check-label{
                  margin-left: 9.5px;
                }
              }
            }
          }
          .filter-btns{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: solid 1px #f1f1f1;
            padding: 9.8px 24px 11px 22px;
            .btn-danger{
              font-size: 14px;
              font-weight: 400;
              min-height: auto;
              text-decoration: underline;
            }
            .btn-primary{
              font-size: 14px;
              min-height: 34px;
              box-shadow: none;
              border: 0 !important;
              padding-left: 28px;
              padding-right: 28px;
              transform: translate(0);
            }
          }
        }
      }
    }
  }

  .general-maintenance-card{
    padding: 18px 27px 23px 20px;
    border-radius: 21px;
    box-shadow: 0 4px 10px 0 rgba(53, 58, 58, 0.04);
    background-color: #dcebe4;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
    @include tablet{
      padding: 22px 31px 36px 20px;
    }
    &::after{
      content: '';
      background-image: url('../Images/pointer.svg');
      width: 44px;
      height: 35px;
      display: flex;
      position: absolute;
      bottom: -28px;
      left: 50px;
      @include tablet{
        left: 60px;
      }
    }
    .general-icon{
      margin-right: 17px;
      width: 56.4px;
      img{
        width: 100%;
      }
      @include tablet{
        max-width: 122px;
        width: 100%;
      }
    }
    .general-title{
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: bold;
      @include tablet{
        font-size: 25px;
      }
      span{
        font-size: 18px;
        font-weight: normal;
        @include tablet{
          font-size: 20px;
        }
      }
    }
    .general-description{
      font-weight: 600;
      margin-top: 10px;
      font-size: 12px;
      @include tablet{
        font-size: 14px;
        margin-top: 8px;
      }
      .read-more-btn{
        font-weight: bold;
        color: $primary-green;
        text-transform: capitalize;
        border: 0;
        box-shadow: none;
        outline: none;
        background: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        margin-left: 3px;
      }
    }
    .btn-green-outline{
      width: 235px;
      margin: 15px auto 0;
      @include tablet{
        width: auto;
        margin: 0;
      }
      .btn-icon{
        display: block;
      }
    }
  }
  .project-list-container{
    padding-bottom: 15px;
    @include tablet{
      padding-bottom: 30px;
    }
    .project-list-wrap{
      padding-top: 3px;
      padding-top: 5px;
      overflow: auto;
      @include tablet{
        overflow: unset;
        display: block;
        margin: 0;
        padding-bottom: 0;
      }
      .project-card{
        padding: 15px 15px 70px;
        box-shadow: 0 4px 10px 0 rgba(53, 58, 58, 0.04);
        background-color: #fff;
        margin: 20px 0 0;
        position: relative;
        border: 1px solid #fff;
        @include tablet{
          padding: 24px 30px 40px 28px;
          max-width: 100%;
          width: 100%;
        }
        .project-card-head{
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          @include tablet{
            margin-bottom: 34px;
          }
          .project-btns{
            display: flex;
            position: absolute;
            bottom: 15px;
            margin-right: 0;
            width: calc(100% - 30px);
            .btn-sm-outline{
              width: 100%;
            }
            @include tablet{
              position: static;
              width: auto;
              .btn-sm-outline{
                width: auto;
              }
            }
          }
          .project-delete-btn{
            position: absolute;
            bottom: 20px;
            width: calc(100% - 30px);
            @include tablet{
              position: static;
              width: auto;
            }
            .btn-delete{
              font-size: 16px;
              font-weight: bold;
              color: $primary-red;
              text-decoration: underline;
              padding: 0;
              min-height: auto !important;
              background-color: transparent;
              box-shadow: none;
              border: 0 !important;
              transform: none;
              margin: 0 auto;
              @include tablet{
                margin: 0;
              }
            }
            .dropdown-menu{
              width: 160px;
              padding: 11px 8px 10px;
              .dropdown-item{
                padding: 0;
                margin: 0;
                font-weight: 600;
                white-space: break-spaces;
                text-align: center;
                background-color: transparent;
              }
            }
          }
          .draft-title{
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: bold;
          }
          .draft-subtitle{
            font-size: 12px;
            margin-top: 2px;
          }
        }
        .project-category{
          font-size: 12px;
        }
        .project-setting-col{
          margin-left: 15px;
          .btn-setting{
            border-radius: 0;
            border: 0 !important;
            min-height: 40px;
            height: 40px;
            width: 40px;
            padding: 0;
            box-shadow: none;
            transform: none;
            &::after{
              display: none;
            }
          }
        }
        .project-card-layout{
          display: flex;
          flex-direction: column;
          @include tablet{
            align-items: stretch;
            flex-direction: row;
          }
          .project-icon{
            display: flex;
            margin-left: auto;
            margin-right: 15px;
            max-width: 62px;
            @include tablet{
              margin: 0 55px 0 0;
              max-width: none;
            }
          }
          .project-detail{
            margin-top: 0;
            @include tablet{
              margin-top: 0;
            }
            .project-title{
              font-family: $secondary-font;
              font-size: 20px;
              font-weight: bold;
              @include tablet{
                font-size: 26px;
                font-size: 34px;
                line-height: 1.06;
              }
            }
            .project-subtitle{
              font-size: 14px;
              font-weight: bold;
              margin-top: 10px;
              display: flex;
              align-items: center;
              @include tablet{
                font-size: 16px;
                margin-top: 15px;
              }
              .ongoing{
                font-size: 12px;
                font-weight: 800;
                padding: 3px 11px;
                border-radius: 13px;
                border: solid 1px $primary-red;
                color: $primary-red;
                margin-left: 5px;
              }
              .cancelled, .pause{
                font-size: 12px;
                font-weight: 800;
                padding: 4px 10px 4px 13px;
                border-radius: 16px;
                background-color: $primary-red;
                color: #fff;
                margin-left: 10px;
                text-transform: uppercase;
              }
            }
            .project-cost-estimate{
              font-size: 21px;
              font-weight: 300;
              margin-top: 6px;
            }
            .project-status{
              font-size: 15px;
              font-weight: bold;
              margin-top: 17px;
              margin-bottom: 15px;
              color: $primary-green;
              @include tablet{
                font-size: 20px;
              }
            }
            .project-checklist{
              font-size: 12px;
              font-weight: 600;
              margin-top: 8px;
              display: flex;
              align-items: center;
              @include tablet{
                font-size: 14px;
              }
              img{
                margin-right: 6.7px;
                @include tablet{
                  margin-right: 11.7px;
                }
              }
            }
          }
          .project-payment-detail{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            margin-top: 25px;
            text-align: center;
            width: 100%;
            max-width: 370px;
            @include tablet{
              margin-left: auto;
              margin-top: 0;
            }
            .project-budget{
              font-family: $secondary-font;
              font-size: 26px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .project-status{
              font-family: $special-font;
              font-size: 20px;
              margin-top: 12px;
              @include tablet{
                font-size: 26px;
              }
            }
            .project-statement{
              font-size: 20px;
              color: $primary-green;
              margin-top: 9px;
            }
            .project-arrow{
              position: absolute;
              left: -60px;
              top: 35px;
              display: none;
              @include tablet{
                display: block;
              }
            }
            .project-progress{
              max-width: 370px;
              width: 100%;
              .progress-month{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 13px;
                .progress-month-value{
                  font-family: $special-font;
                  font-size: 18px;
                  margin: 0 10px;
                  white-space: nowrap;
                }
                .progress-arrow{
                  width: 100px;
                  @include tablet{
                    width: auto;
                  }
                }
              }
              .progress{
                border-radius: 4px;
                box-shadow: 0 2px 0 0 #303734;
                border: solid 1px #303734;
                background-color: #fff;
                .progress-bar{
                  border-radius: 3px;
                  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.07);
                  &.bg-success{
                    background-color: #82c87f !important;
                  }
                }
              }
              .progress-range{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
                .progress-range-value{
                  font-family: $special-font;
                  font-size: 16px;
                  text-align: left;
                  @include tablet{
                    font-size: 18px;
                  }
                }
              }
            }
            &.draft-detail{
              flex: 1 1 auto;
              max-width: 420px;
              .project-status{
                max-width: 300px;
                margin-right: auto;
                position: relative;
                text-align: left;
                margin-top: 0;
                max-width: 245px;
                line-height: 20px;
                @include tablet{
                  margin-top: 12px;
                  text-align: center;
                  display: flex;
                  align-items: flex-end;
                  line-height: normal;
                }
                &::after{
                  content: '';
                  background-image: url('../Images/arrowbl.svg');
                  background-repeat: no-repeat;
                  min-width: 32px;
                  height: 28px;
                  position: absolute;
                  right: 0;
                  bottom: -10px;
                  display: none;
                  @include tablet{
                    right: -40px;
                    display: block;
                  }
                }
                img{
                  margin-left: 12px;
                  margin-top: 14px;
                }
              }
              .btn{
                margin-top: 20px;
                padding-left: 38px;
                padding-right: 38px;
                @include tablet{
                  margin-left: auto;
                  margin-top: 30px;
                  margin-top: 20px;
                  padding-left: 20px;
                  padding-right: 20px;
                }
                .btn-icon{
                  display: block;
                }
              }
            }
            .skipped-months-col{
              padding: 13px 20px 14px 19px;
              background-color: #e73e5a1f;
              display: flex;
              align-items: flex-start;
              margin-top: 15px;
              img{
                margin-top: 5px;
                margin-right: 10px;
              }
              .skipped-text{
                font-size: 16px;
                color: $primary-red;
                text-align: left;
              }
            }
          }
        }
        // Draft Style
        &.card-green{
          border: solid 1px $primary-green;
          background-color: #dcebe4;
          .project-card-head{
            margin-bottom: 10px;
            @include tablet{
              margin-bottom: 34px;
            }
          }
        }
        &.transactions-cancelled{
          border: solid 1px #bababa;
          background-color: #f6f6f6;
          .project-payment-detail{
            opacity: 0.4;
            pointer-events: none;
          }
        }
        &.transactions-pause{
          border: solid 1px $primary-red;
          background-color: #f6f6f6;
        }
      }
    }
  }
}
