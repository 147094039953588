.main-layout{
  min-height: 100vh;
  background-image: url('../Images/background.png');
  background-repeat: repeat;
}
.body-container{
  min-height: calc(100vh - 100px);
  z-index: 1;
}

.row{
  margin-left: -15px;
  margin-right: -15px;
  >.form-col{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.text-danger{
  color: $primary-red !important;
}

.full-text{
  max-width: 100% !important;
  width: 100% !important;
}

// Toaster
.Toastify__toast-container{
  max-width: 473px;
  width: 100% !important;
  bottom: 40px !important;
  .Toastify__toast{
    margin: 0 15px 10px;
    min-height: 70px;
    align-items: center;
    border-radius: 0;
    padding: 23px 20px 21px 23px;
    .Toastify__toast-body{
      font-family: $primary-font !important;
      font-size: 15px;
      font-weight: bold;
      margin: 0;
    }
  }
  .Toastify__toast--error{
    box-shadow: 0 2px 14px 0 rgba(231, 62, 90, 0.29);
    background-color: $primary-red !important;
    .Toastify__toast-icon{
      background-image: url('../Images/toast-errors.svg');
    }
  }
  .Toastify__toast--success{
    box-shadow: 0 2px 14px 0 rgba(43, 153, 103, 0.29);
    background-color: $primary-green !important;
    .Toastify__toast-icon{
      background-image: url('../Images/toast-success.svg');
    }
  }
  .Toastify__toast-icon{
    width: 24px;
    height: 20px;
    background-repeat: no-repeat;
    margin-right: 12px;
    svg{
      display: none;
    }
  }
  .toast-close{
    font-size: 12px;
    text-decoration: underline;
    margin-left: 25px;
    cursor: pointer;
  }
}

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  max-width: 1330px;
  padding-left: 30px;
  padding-right: 30px;
  @include tablet{
    padding-left: 15px;
    padding-right: 15px;

  }
}

.danger-text{
  color: $primary-red !important;
}

// Loader UI
.loader-conatiner{
  width: 50px;
  height: 50px;
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .spinner-border{
    border-width: 3px;
    width: 26px;
    height: 26px;
    &.text-success{
      border-color: $primary-color;
      border-right-color: transparent !important;
    }
  }
}

.dark-dropdown{
  .btn{
    border-radius: 0;
    border: 0 !important;
    padding: 0;
    box-shadow: none;
    transform: none;
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    border: 0;
    border-radius: 0;
    box-shadow: 0 2px 14px 0 rgba(48, 55, 52, 0.19);
    background-color: #303734;
    width: 205px;
    padding: 8px 3px;
    z-index: 9;
    .dropdown-item{
      font-size: 14px;
      padding: 8px 22px;
      margin: 6.5px 0;
      color: #fff;
      &:hover, &:focus, &:active{
        background-color: #282e2b;
      }
      &.disabled{
        opacity: 0.2;
      }
    }
  }
}

.page-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1338px;
  @include tablet{
    max-width: 1308px;
  }
  .back-btn{
    display: flex;
    align-items: center;
    cursor: pointer;
    .back-text{
      font-size: 14px;
      font-weight: bold;
      color: #8d8d8d;
      margin-left: 9px;
      @include tablet{
        font-size: 16px;
      }
    }
  }
}

// Pagination UI
.pagination{
  justify-content: center;
  margin-top: 25px;
  @include tablet{
    margin-top: 10px;
  }
  .page-item{
    .page-link{
      font-size: 14px;
      font-weight: bold;
      color: $primary-text-color;
      background-color: transparent;
      border: 0;
      padding: 2px 8px;
    }
    &.active{
      .page-link{
        color: $primary-green;
      }
    }
    &:first-child{
      margin-right: 24px;
      .page-link{
        background-image: url('../Images/pagination-active.svg');
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
        padding: 0;
        span{
          display: none;
        }
      }
      &.disabled{
        .page-link{
          background-image: url('../Images/pagination-disabled.svg');
          transform: rotate(0deg);
        }
      }
    }
    &:last-child{
      margin-left: 24px;
      .page-link{
        background-image: url('../Images/pagination-active.svg');
        width: 24px;
        height: 24px;
        padding: 0;
        span{
          display: none;
        }
      }
      &.disabled{
        .page-link{
          background-image: url('../Images/pagination-disabled.svg');
          transform: rotate(180deg);
        }
      }
    }
  }
}

// Project status tooltip
.project-status-tooltip{
  opacity: 1 !important;
  padding: 0;
  .tooltip-inner{
    border-radius: 0;
    padding: 20px 24px;
    box-shadow: 0 2px 14px 0 rgba(48, 55, 52, 0.19);
    background-color: #303734;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    max-width: 270px !important;
    color: #fff;
    margin-bottom: 9px;
  }
  .tooltip-arrow{
    display: none;
  }
}
