.signup-layout{
  display: flex;
  padding: 0 30px 25px;
  max-width: 1084px;
  margin: 0 auto;
  @include tablet{
    padding: 35px 30px;
  }
  .signup-left{
    flex: 1 1 auto;
    .form-title{
      font-family: $secondary-font;
      font-size: 28px;
      font-weight: $fw-bold;
      line-height: normal;
      text-align: center;
      @include tablet{
        font-size: 45px;
        line-height: 1.38;
        text-align: left;
      }
    }
    .form-subtitle{
      font-family: $secondary-font;
      font-size: 18px;
      margin-top: 5px;
      text-align: center;
      @include tablet{
        font-size: 21px;
        line-height: 1.38;
        text-align: left;
      }
    }
    .signup-form{
      margin: 0 auto;
      max-width: 430px;
      @include tablet{
        margin: 0;
      }
      .signup-btn{
        margin-top: 30px;
        @include tablet{
          margin-top: 50px;
        }
      }
      .signup-tnc{
        font-size: 15px;
        text-align: center;
        max-width: 410px;
        margin: 28px auto 0;
        a{
          color: $primary-text-color;
          text-decoration: underline;
        }
        .link-text{
          font-weight: bold;
          color: $primary-green;
        }
      }
    }
  }
  .signup-right{
    flex: 1 1 auto;
    padding-left: 80px;
    display: none;
    max-height: 480px;
    position: sticky;
    top: 160px;
    @include tablet{
      display: unset;
    }
    .register-icons{
      display: flex;
      align-items: center;
      text-align: center;
      max-width: 462px;
      margin: 0 auto;
      position: relative;
      .signup-arrow{
        position: absolute;
        left: -80px;
        top: 12px;
      }
      .signup-home{
        margin: 0 auto;
      }
    }
    .register-title{
      font-family: $secondary-font;
      font-size: 49px;
      font-weight: bold;
      line-height: 1.14;
      margin: 30px auto 20px;
      max-width: 462px;
    }
  }
}
.google-captcha-row{
  .recaptcha-custom{
    >div{
      >div{
        margin: 0 auto;
      }
    }
  }
}

// Forgot Password style
.password-layout{
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  &.bank-success, &.bank-balance{
    background-color: $primary-color;
    min-height: 100vh;
    padding: 30px 0;
    .fixed-header{
      position: fixed;
      top: 0;
      width: 100%;
      padding: 30px 20px;
      img{
        max-height: 40px;
        width: auto;
      }
      .close-icon{
        img{
          @media screen and (max-width: 540px) {
            width: 16px;
          }
        }
      }
    }
    .password-card{
      text-align: center;
      padding: 40px 15px;
      .bank-icon{
        margin-bottom: 17px;
      }
      .password-title{
        max-width: 370px;
        margin: 0 auto;
        @include tablet{
          font-size: 29px;
        }
      }
      .btn-danger{
        margin-top: 30px;
        min-height: unset;
      }
    }
  }
  &.bank-balance{
    background-color: transparent;
    .password-card{
      background-color: transparent;
    }
    .password-title{
      max-width: 416px !important;
      margin: 6px auto 0 !important;
      @include tablet{
        font-size: 35px !important;
      }
    }
    .password-subtitle{
      font-family: $primary-font;
      font-size: 16px;
      margin-top: 15px;
    }
  }
}
.password-card{
  max-width: 548px;
  width: 100%;
  padding: 45px 17px;
  margin: 0 0 40px;
  background-color: #fff;
  .password-title{
    font-family: $secondary-font;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    @include tablet{
      font-size: 35px;
    }
  }
  .password-subtitle{
    font-family: $secondary-font;
    font-size: 18px;
    text-align: center;
    margin: 10px auto 0;
    max-width: 444px;
    @include tablet{
      font-size: 21px;
    }
  }
  .password-form{
    max-width: 370px;
    margin: 30px auto 0;
  }
  &.email-card{
    .password-form{
      max-width: 300px;
    }
    .password-title{
      max-width: 390px !important;
    }
    .password-subtitle{
      @include tablet{
        font-size: 20px;
      }
    }
  }
}

// Verify OTP style
.otp-container{
  max-width: 524px;
  margin: 10px 0 30px;
  @include tablet{
    margin: 30px 0;
  }
  .otp-title{
    font-family: $secondary-font;
    font-size: 29px;
    font-weight: bold;
    line-height: 1.38;
    @include tablet{
      font-size: 45px;
    }
  }
  .otp-subtitle{
    font-family: $secondary-font;
    font-size: 18px;
    margin-top: 5px;
    max-width: 410px;
    @include tablet{
      font-size: 21px;
      margin-right: 20px;
      max-width: none;
    }
    span{
      font-weight: bold;
      color: $primary-green;
      &.edit-num{
        margin-left: 5px;
        cursor: pointer;
        img{
          margin-bottom: 4px;
        }
      }
    }
  }
  .otp-input-container{
    margin: 40px 0;
    @include tablet{
      margin: 45px 0 50px;
    }
    .otp-error-row{
      display: flex;
      align-items: baseline;
      margin-bottom: 16px;
      .otp-error{
        font-size: 15px;
        font-weight: bold;
        color: $primary-red;
        margin-left: 7px;
      }
    }
    .otp-input{
      input{
        width: 50px !important;
        height: 60px;
        border: solid 1px $primary-text-color;
        border-left: 0;
        font-size: 28px;
        @include tablet{
          font-size: 40px;
          width: 76px !important;
          height: 95px;
        }
        &::-webkit-input-placeholder { /* Edge */
          font-size: 24px;
          color: #d3d3d3;
          opacity: 1;
          @include tablet{
            font-size: 40px;
          }
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-size: 24px;
          color: #d3d3d3;
          opacity: 1;
          @include tablet{
            font-size: 40px;
          }
        }
        &::placeholder {
          font-size: 24px;
          color: #d3d3d3;
          opacity: 1;
          @include tablet{
            font-size: 40px;
          }
        }
      }
      &:first-child{
        input{
          border-left: solid 1px $primary-text-color;
        }
      }
      .otp-input-seprator{
        display: none;
      }
      &:nth-child(3){
        .otp-input-seprator{
          display: block;
          color: transparent;
          background-image: url('../Images/dash.svg');
          background-repeat: no-repeat;
          width: 16px;
          height: 2px;
          margin: 0 10px;
          @include tablet{
            margin: 0 20px;
            height: 3px;
            width: 18.8px;
          }
        }
      }
      &:nth-child(4){
        input{
          border-left: solid 1px $primary-text-color;
        }
      }
    }
  }
  .otp-helper-text{
    font-size: 15px;
    .resend-btn{
      color: $primary-green;
      text-decoration: underline;
      cursor: pointer;
      border: 0;
      background: none;
      padding: 0;
      margin-left: 3px;
    }
  }
}
.number-form{
  margin: 28px auto 0;
  max-width: 370px;
}

// Google captcha 
.rc-anchor-alert{
  .rc-anchor{
    background-color: red;
  }
}
.rc-anchor-light{
  background: blue;
}
.rc-anchor-normal{
  height: 400px !important;
}
