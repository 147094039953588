.react-datepicker__tab-loop{
  .react-datepicker-popper{
    z-index: 9;
  }
  .react-datepicker{
    border: solid 1px $primary-text-color;
    background-color: #fff;
    border-radius: 0;
    .react-datepicker__month-container{
      .react-datepicker__header{
        padding: 24px 30px 19px 29px;
        background-color: #82c87f;
        border-bottom: 1px solid $primary-text-color;
        border-radius: 0;
        .react-datepicker__current-month{
          display: none;
        }
        .react-datepicker__header__dropdown{
          .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container{
            margin: 0 6px;
            .react-datepicker__month-select, .react-datepicker__year-select{
              padding: 8px 26px;
              border-radius: 20px;
              box-shadow: 0 3px 0 0 $primary-text-color;
              border: solid 1.5px $primary-text-color;
              background-color: #fff;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
            }
          }
        }
        .react-datepicker__day-names{
          margin-top: 26px;
        }
      }
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
      width: 36px;
      height: 36px;
      font-size: 14px;
      color: $primary-text-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      border-radius: 50%;
      &.react-datepicker__day--keyboard-selected, &.react-datepicker__day--selected, &.react-datepicker__day--today{
        background-color: #2b9967;
        color: #fff;
      }
    }
    .react-datepicker__navigation{
      top: 32px;
      .react-datepicker__navigation-icon{
        &::before{
          border-color: $primary-text-color;
        }
      }
    }
    .react-datepicker__triangle{
      &::before{
        border-top-color: $primary-text-color !important;
      }
      &::after{
        border-bottom-color: #f8f8f8;
      }
    }
  }
}
