@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@400;500;600;700;800&family=Grape+Nuts&family=Nunito+Sans:wght@300;400;600;700;800&display=swap');

$primary-font: 'Nunito Sans', sans-serif;
$secondary-font: 'Crimson Pro', serif;
$special-font: 'Grape Nuts', cursive;
$fw-light: 300;
$fw-reg: 400;
$fw-med: 500;
$fw-sbold: 600;
$fw-bold: 700;
$fw-xbold: 800;

$primary-color: #82c87f;  
$primary-text-color: #303734;
$grey-text: #8d8d8d;
$primary-red: #e73e5a;
$primary-green: #2b9967;

$secondary-text-color: #6f788d;
$white: #fff;
$black: #000;
