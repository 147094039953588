@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 425px) {
    @content;
  }
}
