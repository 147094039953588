.form-control, .form-select{
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 14px 10px;
  line-height: normal;
  border-radius: 0;
  font-size: 15px;
  background-color: #fff;
  min-height: 48px;
  border: solid 1px #303734;
  margin-bottom: 7px;
  -webkit-box-shadow: 0 0 0 50px #fff inset !important;
  display: inline-block;
  @media screen and (max-width: 600px) {
    font-size: 16px !important;
  }
  &::-webkit-inner-spin-button{
    display: none;
  }
  &:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 50px #fff inset !important;
  }
  &.invalid{
    border-color: $primary-red;
  }
  &:focus{
    border-color: $primary-color;
  }
  &:disabled{
    border-color: #ccc;
    color: #8d8d8d;
    background-color: #fff;
  }
  &.form-select{
    -webkit-box-shadow: unset !important;
    background-image: url('../Images/select-icon.svg');
    background-size: 12px 6.5px;
    background-repeat: no-repeat;
  }
}
input::-webkit-inner-spin-button{
  display: none;
}
.form-col{
  position: relative;
  margin-bottom: 23px;
  @include tablet{
    margin-bottom: 28px;
  }
  .form-label{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: -10px;
    background: #fff;
    z-index: 1;
    .red-star{
      color: #e73e5a;
    }
  }
  .input-action{
    position: relative;
    .form-control{
      padding-right: 61px;
    }
    .input-btn{
      font-size: 14px;
      font-weight: bold;
      color: #2b9967;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .form-helper-text{
    font-size: 14px;
    font-weight: 600;
    color: $grey-text;
    margin-top: 3px;
    @include tablet{
      font-size: 15px;
      font-weight: bold;
    }
    &.invlaid-text{
      color: $primary-red;
    }
  }
  &.phone-input{
    position: relative;
    .phone-code{
      position: absolute;
      left: 32px;
      top: 15px;
      font-size: 15px;
      font-weight: bold;
      color: #8d8d8d;
      .intl-tel-input{
        width: 58px;
        height: 24px;
        .flag-container{
          padding: 0;
          border-right: 1px solid $primary-text-color;
          .selected-flag{
            width: 43px;
            background: transparent !important;
          }
          .arrow{
            margin-left: 15px;
            &::after{
              content: '';
              background-image: url('../Images/country-dropdown.svg');
              width: 7px;
              height: 5px;
              background-repeat: no-repeat;
              display: block;
            }
          }
        }
        input{
          display: none;
        }
        // Country list style
        .country-list{
          left: -16px;
          max-height: 306px;
          max-width: 430px;
          min-width: 430px;
          border: solid 1px $primary-text-color;
          box-shadow: none;
          top: 32px;
          white-space: unset;
          @media screen and (max-width: 489px) {
            min-width: calc(100vw - 40px);
          }

          // Custom Scrollbar
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: #d5d5d5;
            border-radius: 4px;
          }
          .country{
            padding: 7.5px 16px;
            .country-name, .dial-code{
              font-size: 15px;
              font-weight: normal;
              color: $primary-text-color;
            }
            &.highlight{
              background: transparent;
              .country-name, .dial-code{
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .form-control{
      padding-left: 90px;
    }
  }
}
.input-unit{
  width: 100px;
  height: 30px;
  border: 0;
  border-left: 1px solid $primary-text-color;
  padding: 3px 14px;
  font-size: 14px;
  font-weight: bold;
  @include tablet{
    font-size: 18px;
    width: 148px;
  }
  &::-webkit-inner-spin-button{
    display: none;
  }
  &::placeholder {
    color: #8d8d8d;
    opacity: 0.9;
    font-size: 18px;
    font-weight: $fw-bold;
  }
  
  &:-ms-input-placeholder {
   color: #8d8d8d;
   opacity: 0.9;
   font-size: 18px;
   font-weight: $fw-bold;
  }
  
  &::-ms-input-placeholder {
   color: #8d8d8d;
   opacity: 0.9;
   font-size: 18px;
   font-weight: $fw-bold;
  }
}

.validation-error{
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  color: $primary-red;
}

// Custom checkbox & radio

.category-check, .estimate-radio, .disclosure-row, .filter-checkbox{
  display: flex;
  justify-content: flex-end;
  .form-check-input{
    width: 22px;
    height: 22px;
    border: solid 1.5px #303734;
    margin: 0 !important;
    border-radius: 0;
    box-shadow: none !important;
    cursor: pointer;
    @include tablet{
      width: 25px;
      height: 25px;
    }
    &:checked{
      background-image: url('../Images/check-box.svg');
      background-size: 13.7px 9.7px;
      background-position: center;
      background-color: $primary-color;
    }
  }
  &.estimate-radio{
    .form-check-input{
      border-radius: 50%;
      &:checked{
        background-size: 12.7px 9px;
        background-position: center;
      }
    }
  }
}

// Google search input
.search-location-wrap{
  flex: 1 1 auto;
  .pac-target-input{
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    border: 0 !important;
    padding-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include tablet{
      font-size: 18px;
    }
    @media screen and (max-width: 540px) {
      padding-right: 0;
    }
    &::placeholder {
      color: #8d8d8d;
      opacity: 0.9;
      font-size: 14px;
      font-weight: $fw-bold;
      @include tablet{
        font-size: 18px;
      }
    }
    
    &:-ms-input-placeholder {
     color: #8d8d8d;
     opacity: 0.9;
     font-size: 14px;
     font-weight: $fw-bold;
     @include tablet{
       font-size: 18px;
     }
    }
    
    &::-ms-input-placeholder {
     color: #8d8d8d;
     opacity: 0.9;
     font-size: 14px;
     font-weight: $fw-bold;
     @include tablet{
       font-size: 18px;
     }
    }
  }
  &.invalid{
    .pac-target-input{
      border-color: $primary-red !important;
    }
  }
}

.account-address{
  .search-location-wrap{
    .pac-target-input{
      padding: 16px 14px 10px;
      line-height: normal;
      border-radius: 0;
      font-size: 15px;
      font-weight: normal;
      background-color: #fff;
      min-height: 48px;
      border: solid 1px #303734 !important;
      margin-bottom: 7px;
      box-shadow: 0 0 0 50px #fff inset !important;

      &::placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &::-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  &.invalid{
    .pac-target-input{
      border-color: $primary-red !important;
    }
  }
}

// Google location style{
.pac-container{
  background-color: #fff;
  box-shadow: 0 4px 0 0 #000;
  border: solid 1.5px #000;
  padding: 15px 4px;
  width: 100%;
  z-index: 9999;
  border-radius: 0;
  font-family: $primary-font;
  margin-top: 9px !important;
  .pac-item{
    padding: 16px 30px 18px;
    line-height: normal;
    border: 0;
    white-space: break-spaces;
    .pac-icon{
      display: none;
    }
    span{
      font-family: $primary-font;
      font-size: 16px;
      font-weight: bold;
      color: #353a3a;
      line-height: normal;
    }
    &:hover{
      background-color: rgba(130, 200, 127, 0.16);
    }
  }
  &::after{
    display: none;
  }
  @media screen and (max-width: 540px) {
    margin-top: 16px !important;
    width: calc(100% - 40px) !important;
    left: 20px !important;
    .pac-item{
      padding: 12px 20px 13px;
      span{
        font-size: 14px;
      }
    }
  }
}
