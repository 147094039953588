.btn {
  min-height: 55px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $primary-font;
  letter-spacing: normal;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: all linear .2s;
  position: relative;
  &:disabled, &.disabled{
    opacity: 1;
    cursor: not-allowed;
  }
  @include tablet{
    padding-left: 30px;
    padding-right: 30px;

  }
  .btn-icon{
    margin-left: 11px;
    display: none;
    @include tablet{
      display: block;
    }
  }
  &.btn-primary{
    box-shadow: 0 3px 0 0 #303734;
    border: solid 1.5px #303734 !important;
    background-color: #82c87f;
    color: $primary-text-color;
    &:hover{
      box-shadow: none;
      transform: translateY(3px);
    }
    &:active{
      background-color: $primary-green;
      color: $primary-text-color;
    }
  }
  &.btn-cancel{
    border: 0;
    border-radius: 0;
    font-size: 13px;
    font-weight: normal;
    color: $primary-red;
    min-height: unset;
  }
  &.btn-login{
    min-height: 40px;
    height: 40px;
    padding: 9px 15px;
    border-radius: 4px;
    border: solid 1px #8d8d8d;
    font-size: 16px;
    font-weight: normal;
    color: #8d8d8d;
  }
  &.btn-danger{
    font-size: 16px;
    font-weight: bold;
    border: 0;
    background-color: transparent !important;
    color: $primary-red !important;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
  }
  &.btn-outline{
    background-color: #fff;
    border: solid 1.5px #303734 !important;
  }
  &.btn-green-outline{
    border: solid 1.5px $primary-green !important;
    color: $primary-green;
    background-color: transparent;
    min-height: 40px;
    height: 40px;
    padding: 10px 22px 9px 25px;
    font-size: 14px;
    line-height: normal;
  }
  .sm-hide{
    display: none;
    @include tablet{
      display: contents;
    }
  }
  &.btn-sm-outline{
    border-radius: 0;
    min-height: 40px;
    height: 40px;
    padding: 12px 24px 9px 25px;
    border: solid 1px #f0f0f0;
    background-color: #fff;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: normal;
  }
  .btn-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border{
      position: static;
      width: 24px;
      height: 24px;
      border-width: 2px;
      background: transparent;
      box-shadow: none;
    }
  }
}
.btn-location{
  min-width: 44px;
  width: 44px;
  height: 44px;
  background-color: #82c87f;
  border: 0;
  @include tablet{
    width: 64.8px;
    height: 64px;
  }
}
