.selected-property-slider{
    display: block;
    width: 100%;
    padding: 0 30px;

    .slick-slider{
      .slick-list{
        @include tablet{
          max-height: 86px;
        }
        .slick-track{
          margin-left: 0;
          margin-right: 0;
          .slick-slide{
            height: 86px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 0 15px;
            @media screen and (max-width: 600px) {
              margin-top: -10px;
            }
          }
          @media screen and (max-width: 600px) {
            transform: none !important;
            width: auto !important;
            max-height: 260px;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            width: 240px !important;
            margin: 0 auto;
            ::-webkit-scrollbar {
              display: none;
            }
            // Custom Scrollbar transparent scroll
            &::-webkit-scrollbar {
              width: 0;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background: transparent;
            }
            .slick-slide{
              float: none;
              padding: 0;
              width: 240px !important;
            }
          }
        }
      }
    }
    .slick-arrow{
      width: 30px;
      height: 30px;
      bottom: 18px;
      transform: none;
      top: unset;
      @media screen and (max-width: 600px) {
        display: none !important;
      }
    }
    .slick-prev{
      left: -35px;
      &:before{
        transform: rotate(180deg);
        background: url('../../Assets/Images/carousel-right.svg') center no-repeat;
        font-size: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;
      }
    }
    .slick-next{
      right: -35px;
      &:before{
        background: url('../../Assets/Images/carousel-right.svg') center no-repeat;
        font-size: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;
      }
    }
  }
  
  