.main-header{
  padding: 30px 0;
  position: sticky;
  top: 0;
  z-index: 999;
  .header-logo{
    img{
      height: 34px;
      @include tablet{
        height: auto;
      }
    }
  }
  &.header-bg{
    background-color: #f9f9f9;
    border-bottom: 1px solid #dbdbdb;
  }
  &.bg-dark{
    background-color: $primary-text-color !important;
    padding: 20px 0 10px;
    height: 184px;
    @include tablet{
    padding: 24px 0 26px;
    height: auto;
    }
    .header-logo{
      padding-bottom: 20px;
      @include tablet{
        padding-bottom: 29px;
      }
    }
  }
  .header-row{
    position: relative;
    >.container{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .header-cross{
      cursor: pointer;
      .btn-logout{
        font-size: 16px;
        font-weight: bold;
        color: $primary-red;
        min-height: 40px;
        padding-left: 0;
        padding-right: 0;
        border: 0;
      }
    }
    .header-property-address-row{
      justify-content: center;
      padding-bottom: 20px;
      @include tablet{
        margin-bottom: 29px;
        padding-bottom: 0;
      }
      .header-property-col{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 343px;
        min-width: 343px;
        width: 100%;
        padding: 8px 8px 8px 24px;
        color: #ddd;
        border-radius: 30px;
        background-color: #242c28;
        cursor: pointer;
        .prop-icon{
          margin-right: 9.7px;
          height: 22px;
          width: 22px;
          min-width: 22px;
        }
        .prop-address{
          font-size: 14px;
          font-weight: 800;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: auto;
          &:hover{
            color: #fff;
          }
        }
        .dropdown-icon{
          width: 24px;
          min-width: 24px;
          height: 24px;
          padding: 5px;
          display: flex;
          margin-left: 12px;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          background-color: #303734;
        }
      }
    }
    .profile-dropdown{
      padding-bottom: 20px;
      @include tablet{
        padding-bottom: 29px;
      }
      cursor: pointer;
      .profile-name{
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        text-transform: capitalize;
        display: flex;
        img{
          margin-left: 7px;
        }
        .spinner-border{
          &.text-success{
            color: $primary-color !important;
            margin-right: 10px;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
    .header-property-list-row{
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      color: #fff;
      background-color: $primary-text-color;
      border-top: 1px solid #434745;
      padding: 18px 0 50px;
      z-index: 9;
      &::before{
        content: '';
        position: absolute;
        top: -34px;
        left: 50%;
        transform: translateX(-50%);
        height: 40px;
        width: 350px;
        z-index: 9;

      }
      .property-label{
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        @include tablet{
          text-align: left;
        }
      }
      .property-row{
        display: flex;
        align-items: center;
        margin-top: 4px;
        flex-direction: column;
        @include tablet{
          flex-direction: row;
        }
        .add-property-btn{
          width: 240px;
          height: 66px;
          padding: 22px 20px;
          border-radius: 4px;
          text-align: center;
          background-color: rgba(255, 255, 255, 0.05);
          cursor: pointer;
          font-size: 16px;
          color: #8d8d8d;
          margin-top: 22px;
          &:hover{
            color: $primary-color;
          }
          @include tablet{
            width: 181px;
          }
        }
        .property-list-row{
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 30px;
          @include tablet{
            margin-top: 0;
            margin-left: 37px;
            width: calc(100% - 218px);
          }
          .property-card{
            max-width: 270px !important;
            height: 66px;
            margin: 0 auto;
            padding: 14px 43px 14px 12px;
            border-radius: 4px;
            border: 1px solid #3b413e;
            background-color: rgba(255, 255, 255, 0.05);
            display: flex !important;
            align-items: center;
            position: relative;
            cursor: pointer;
            @media screen and (max-width: 992px) {
              max-width: none !important;
            }
            &.selected-property{
              border: solid 1px #82c87f;
              cursor: default;
              .selected-label{
                position: absolute;
                bottom: calc(100% + 6px);
                left: 0;
                font-size: 12px;
                font-weight: 600;
                color: $primary-color;
                cursor: default;
              }
            }
            .property-icon{
              margin-right: 9px;
              display: inline-block;
              img{
                min-width: 34px;
              }
            }
            .property-name{
              font-size: 14px;
              font-weight: 600;
              color: #fff;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 126px;
              @media screen and (max-width: 992px) {
                max-width: none !important;
              }
            }
            .prop-delete-btn{
              position: absolute;
              right: 10px;
              bottom: 10px;
              cursor: pointer;
              opacity: 0.16;
              &:hover{
                opacity: 1;
              }
            }
          }
          .property-carousel-arrow{
            cursor: pointer;
            &.arrow-left{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  .header-bottom{
    .container{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .property-detail-row{
      position: relative;
      &:hover{
        .property-detail-popup{
          display: block;
        }
      }
      .property-detail-btn{
        font-size: 14px;
        font-weight: bold;
        color: #f0f0f0;
        cursor: pointer;
        @include tablet{
          font-size: 16px;
        }
        img{
          margin-bottom: 2px;
          margin-right: 5px;
          width: 15px;
          @include tablet{
            width: auto;
            margin-right: 10px;
          }
        }
      }
    }
    .header-saving-col{
      text-align: center;
      .saving-value{
        font-family: $secondary-font;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.06;
        color: $primary-color;
        @include tablet{
          font-size: 34px;
        }
      }
      .saving-text{
        font-size: 12px;
        color: #fff;
        margin-top: 1px;
        @include tablet{
          font-size: 13px;
          margin-top: 7px;
        }
      }
    }
    .project-btn{
      margin-top: 13px;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 20px;
      min-width: 158px;
      @include tablet{
        margin-top: 0;
        width: auto;
        position: static;
      }
      .btn-outline{
        min-height: 40px;
        border-radius: 28px;
        box-shadow: 0 3px 0 0 #303734;
        border: solid 1.5px #303734;
        padding: 9px 25px;
        font-size: 16px;
        font-weight: bold;
        background-color: $primary-color;
        color: $primary-text-color;
        margin: 0 auto;
        width: 315px;
        @include tablet{
          width: auto;
          color: #fff;
          max-width: none;
          max-width: 300px;
          background-color: $primary-text-color;
          box-shadow: 0 3px 0 0 #fff;
          border: solid 1.5px #fff !important;
        }
        @media screen and (max-width: 600px) {
          width: calc(100% - 60px);
        }
        &:hover{
          box-shadow: none;
          transform: translateY(3px);
        }
      }
    }
  }
}

h3 {
  background: #5f9ea0;
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
}
