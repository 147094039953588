
* {
  margin: 0;
  padding: 0;
}
*:focus {
  outline: none;
}
body {
  font-family: $primary-font !important;
  font-size: 14px;
  font-weight: normal;
  color: $primary-text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #f8f8f8;
  overflow-y: auto !important;
  &.modal-open{
    overflow: hidden !important;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
textarea {
  resize: none;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input {
  -webkit-box-shadow: 0 0 0 50px transparent inset !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}
select::-ms-expand {
  display: none;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
input,
select,
textarea {
  vertical-align: middle;
  -webkit-appearance: none;
  border-radius: 0;
}
label {
  font-weight: normal;
}
::placeholder {
  color: $white;
  opacity: 0.5;
  font-size: 14px;
  font-weight: $fw-med;
}

:-ms-input-placeholder {
 color: $white;
 opacity: 0.5;
 font-size: 14px;
 font-weight: $fw-med;
}

::-ms-input-placeholder {
 color: $white;
 opacity: 0.5;
 font-size: 14px;
 font-weight: $fw-med;
}
h1,
h2,
h3,
h4,
h5,
p {
  word-wrap: break-word;
}
img {
  max-width: 100%;
  height: auto;
}
a:link,
a:visited,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  outline-width: 0;
}
.pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.pointer-none {
  pointer-events: none;
}
.not-allowed {
  cursor: not-allowed !important;
}
.border-none {
  border: none !important;
}
.auto-wid {
  width: auto;
}
.inline-block {
  display: inline-block;
}
.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.word-break {
  word-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
.valign-middle {
  vertical-align: middle;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.d-grid {
  display: grid;
}
.pd0 {
  padding: 0 !important;
}
.pd {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.rounded-img {
  border-radius: 50%;
}
.pt-20{
  padding-top: 20px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-10 {
  padding-right: 10px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-10 {
  margin-top: 10px;
}

p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
