.modal-card-reissue{
  max-width: 527px !important;
  .modal-card{
    max-width: 370px;
    margin: 0 auto;
  }
  .modal-body {
    padding: 40px 40px 31px;
    .profile-back{
      display: none;
    }
  }
  @media screen and (max-width: 460px) {
    min-height: 100vh;
    margin: 0;
    padding: 0 !important;
    .modal-content{
      min-height: 100vh;
      .modal-body{
        padding: 80px 30px 30px;
        .modal-card{
          position: relative;
          .profile-back{
            display: block;
            position: absolute;
            top: -50px;
            left: -4px;
          }
          .modal-title{
            font-size: 28px !important;
          }
        }
      }
    }
  }
}
.modal-dialog{
  max-width: 485px;
  margin-left: auto;
  margin-right: auto;
  transform: scale(1) !important;
  .modal-content{
    border-radius: 0;
    border: 0;
    background-color: #f9f9f9;
  }
  &.modal-md{
    max-width: 485px;
  }
  &.modal-lg{
    max-width: 615px;
  }
  &.modal-sm{
    max-width: 415px;
    .modal-title{
      margin-top: 0;
      @include tablet{
        font-size: 25px;
      }
    }
    .modal-subtitle{
      @include tablet{
        font-size: 14px;
      }
      .password-note{
        margin-top: 20px;
        text-align: left;
      }
    }
    &.modal-dialog-centered{
      @include mobile{
        align-items: flex-end;
        padding: 0;
        height: 100%;
        margin: 0 !important;
        max-width: 100%;
        .modal-body{
          padding: 34px 30px 36px;
          .modal-btns{
            flex-direction: column-reverse;
            .btn-danger{
              margin-top: 30px;
              min-height: 0;
            }
          }
        }
      }
    }
  }
  &.modal-right{
    margin: 0 -450px 0 auto;
    min-height: 100vh;
    height: 100%;
    max-width: 450px;
    transition: margin .2s linear !important;
    .modal-content{
      height: 100%;
    }
    .modal-body{
      background-color: #f9f9f9;
      background-image: url('../Images/background.png');
      background-repeat: repeat;
      background-size: auto 27px;
      padding: 40px 35px;
      position: relative;
    }
    .modal-close{
      img{
        width: 12.7px;
      }
    }
  }
  &.modal-xs{
    max-width: 354.4px;
    .modal-body{
      padding: 40px 21px 45px;
    }
    .modal-title{
      font-size: 25px;
    }
    .modal-subtitle{
      font-size: 14px;
      text-align: center;
      span{
        font-weight: bold;
      }
    }
    .card-btn{
      max-width: 223px;
      margin: 29px auto 0;
      .btn{
        min-height: 47px;
      }
    }
  }
}
.modal{
  .modal-dialog-centered{
    @media screen and (max-width: 460px) {
      padding: 0 20px;
    }
  }
  background-color: #00000066;
  &.show{
    .modal-right{
      margin-right: 0;
    }
  }
}
.modal-backdrop.show{
  opacity: 0.7;
  @include mobile{
    opacity: 0.5;
  }
}
.modal-body{
  padding: 30px 20px;
  background-color: #fff;
  @include tablet{
    padding: 40px;
  }
  .modal-title{
    font-family: $secondary-font;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    line-height: normal;
    @include tablet{
      font-size: 35px;
    }
  }
  .modal-cancel{
    margin-top: 24px;
    .btn{
      margin: 0 auto;
    }
  }
  .modal-close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .modal-btns{
    max-width: 370px;
    margin: 30px auto 0;
    &.sync-btn{
      .btn-primary{
        min-height: 55px;
      }
    }
  }
}

// Profile detail modal
.profile-detail-modal{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  max-width: 450px;
  width: 100%;
  padding: 85px 30px 30px;
  background-color: #fff;
  background-image: url('../Images/background.png');
  background-repeat: repeat;
  background-size: auto 27px;
  z-index: 999;
  overflow-y: auto;
  @include tablet{
    right: 100%;
    padding: 90px 50px 50px;
  }
  .profile-back{
    position: absolute;
    top: 30px;
    left: 26px;
    cursor: pointer;
    @include tablet{
      display: none;
    }
  }
  .profile-title{
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    @include tablet{
      margin-bottom: 40px;
      font-size: 24px;
    }
  }
  .card-container{
    max-width: 332px;
    margin: 0 auto;
    .card-img{
      margin-bottom: 3px;
      display: table-cell;
      &.locked{
        position: relative;
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          border-radius: 13px;
          opacity: 0.3;
        }
        .lock-icon{
          position: absolute;
          left: 0;
          top: 0;
          z-index: 9;
        }
      }
    }
    .card-button{
      margin-top: 24px;
      border: 0;
      background: transparent;
      box-shadow: none;
      font-size: 15px;
      font-weight: bold;
      color: $primary-green;
      display: flex;
      align-items: center;
      .btn-icon{
        margin-left: 8px;
      }
    }
    .card-lock-row{
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      position: relative;
      .lock-text{
        font-size: 15px;
        font-weight: bold;
      }
      .custom-switch{
        .form-switch{
          .form-check-input{
            width: 45px;
            height: 24px;
            background-color: #8d8d8d;
            border: 0;
            margin: 0;
            background-image: url('../Images/switch.svg');
            background-size: auto;
            background-position: 2px center;
            &:checked{
              background-color: $primary-color;
              background-position: right 2px center;
            }
          }
        }
      }
      .lock-reason-container{
        position: absolute;
        top: calc(100% + 12px);
        right: 0;
        width: 100%;
        padding: 25px;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
      }
    }
  }
  .btn:disabled, .btn.disabled{
    opacity: 0.6;
  }
}

.mail-modal{
  .modal-card{
    max-width: 370px;
    margin: 0 auto;
  }
  .modal-body{
    padding: 45px 40px 34px;
    background-color: #f9f9f9;
  }
  .number-form{
    margin-top: 0;
  }
  .modal-title{
    text-align: left;
  }
  .modal-subtitle{
    margin-top: 10px;
  }
  .profile-form{
    margin-top: 40px;
  }
}

.project-trans-modal{
  .modal-content, .modal-body{
    background-color: #fff !important;
  }
  .text-project-transactions{
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
  }
  .project-transaction{
    .transaction-list{
      margin-top: 20px;
    }
  }
}

// General maintennace modal
.general-maintenance-modal{
  display: none !important;
  .modal-dialog{
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    max-width: none;
    .modal-content{
      background-color: #dcebe4;
      padding: 33px 30px 32px;
      .modal-body{
        background-color: transparent;
        padding: 0;
      }
      .modal-close{
        position: fixed;
        top: 15px;
        right: 25px;
        width: 12.7px;
        height: 12.7px;
      }
      .general-icon{
        width: 75px;
        margin: 0 auto;
      }
      .general-title{
        font-family: $secondary-font;
        font-size: 22px;
        font-weight: bold;
        margin: 16px auto 0;
        text-align: center;
        max-width: 268px;
        span{
          font-weight: normal;
        }
      }
      .general-description{
        font-size: 12px;
        margin-top: 10px;
      }
      .general-btn{
        max-width: 315px;
        width: 100%;
        margin: 35px auto 0;
        .btn{
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 575px) {
    display: flex !important;
    align-items: flex-end;
  }
}