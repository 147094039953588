.onboarding-search-layout{
  padding: 5px 20px 20px;
  max-width: 812px;
  .search-image{
    text-align: center;
  }
  .onboarding-title{
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: bold;
    margin: 13px auto 0;
    text-align: center;
    max-width: 584px;
    @include tablet{
      font-size: 45px;
    }
  }
  .search-text{
    font-size: 15px;
    .asterik{
      color: $primary-green;
    }
    &.mobile-show{
      display: none;
      @media screen and (max-width: 540px) {
        display: block;
        font-size: 14px;
      }
    }
    &.mobile-hide{
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
  }
  .search-property-form{
    margin: 45px 0;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 6px 6.1px 6px 10px;
    box-shadow: 0 4px 0 0 #000;
    border: solid 1.5px #000;
    background-color: #fff;
    @include tablet{
      height: 76px;
      margin: 40px 0 30px;
      padding: 6px 6.1px 6px 25px;
    }
    .search-location-row{
      display: flex;
      align-items: center;
      flex: 1 1 auto;
    }
    .me-3{
      margin-right: 14px !important;
      img{
        min-width: 17px;
      }
    }
    @media screen and (max-width: 540px) {
      flex-direction: column;
      padding: 0;
      border: 0;
      box-shadow: none;
      background-color: transparent;
      height: auto;
      .search-location-row, .input-unit{
        min-height: 55px;
        padding: 16px 15px;
        box-shadow: 0 4px 0 0 #000 !important;
        border: solid 1.5px #000;
        background-color: #fff;
        margin-bottom: 20px;
        width: 100%;
      }
      .input-unit{
        &::placeholder {
          font-size: 14px;
          @include tablet{
            font-size: 18px;
          }
        }
        
        &:-ms-input-placeholder {
        font-size: 14px;
        @include tablet{
          font-size: 18px;
        }
        }
        
        &::-ms-input-placeholder {
        font-size: 14px;
        @include tablet{
          font-size: 18px;
        }
        }
      }
      .me-3{
        margin-right: 8px !important;
        img{
          min-width: 13px;
          height: 17px;
        }
      }
      .btn-location{
        min-height: 56px;
        border-radius: 34px;
        box-shadow: 0 3px 0 0 #303734;
        border: solid 1.5px #303734 !important;
        background-color: #82c87f;
        color: $primary-text-color;
        width: 100%;
        margin-top: 40px;
        .btn-text{
          margin-right: 11px;
          font-size: 16px;
          font-weight: bold;
          display: inline-block !important;
        }
      }
    }
  }
}

// Add property layout
.onboarding-add-layout{
  max-width: 868px;
  padding-top: 5px;
  padding-bottom: 20px;
  @include tablet{
    padding: 5px 20px 20px;
  }
  .address-row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include tablet{
      flex-direction: row;
    }
    .pin-icon{
      min-width: 22px;
      margin-right: 11.5px;
      margin-top: 5px;
      + .onboarding-title{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 13px;
        line-height: normal;
        white-space: normal;
        margin-bottom: 11px;
        text-align: center;
        @include tablet{
          display: inline;
          -webkit-line-clamp: unset;
          -webkit-box-orient: unset;
          white-space: nowrap;
          line-height: 1.38;
          text-overflow: ellipsis;
          margin-top: 0;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
    &.saving-account{
      .onboarding-title{
        line-height: normal;
        white-space: normal;
        text-align: center;
      }
    }
  }
  .onboarding-title{
    font-family: $secondary-font;
    font-size: 27px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include tablet{
      line-height: 1.38;
      font-size: 45px;
    }
  }
  .onboarding-subtitle{
    font-family: $secondary-font;
    font-size: 18px;
    margin: 12px auto 0;
    text-align: center;
    max-width: 292px;
    @include tablet{
      font-size: 21px;
      max-width: none;
    }
  }
  .search-btn{
    font-size: 16px;
    font-weight: bold;
    color: $primary-green;
    text-decoration: underline;
    margin-top: 3px;
    text-align: center;
    a{
      color: $primary-green;
      cursor: pointer;
    }
  }
  .add-property-form{
    margin: 50px auto;
    .add-property-content{
      div{
        font-size: 15px;
        font-weight: bold;
        line-height: 1.47;
        &+div{
          font-weight: normal;
        }
        span{
          color: $primary-red;
        }
      }
    }
  }
  .onboarding-btn{
    margin: 15px auto 0;
    max-width: 370px;
    .btn{
      width: 100%;
    }
    @include tablet{
      margin: 46px auto 0;
    }
  }
  .saving-icon{
    text-align: center;
    margin-bottom: 23px;
    @include tablet{
      margin-bottom: 12.3px;
    }
  }
  &.tenure-selection-layout{
    padding: 55px 0 0;
    @include tablet{
      padding: 30px 0 5px;
    }
  }
}
